import { IArticle, IAttribute, IInsuranceFixedPrice, InventoryType, IQuantityPerUnit } from '@pia/pia.shared';
import { ISearchable } from 'src/app/common/contracts/components/searchable';
import { AggregateRoot } from '../aggregate-root';

export class ArticleDto extends AggregateRoot implements IArticle, ISearchable {
  directOrder?: boolean;
  substituteArticleCustomerNumber: string;
  attributes: IAttribute[];
  inventoryType: InventoryType;
  unavailableTo: Date;
  unavailableFrom: Date;
  insuranceFixedPrice: IInsuranceFixedPrice[] = [];

  pcn: string;
  name: string;
  description: string;
  manufacturerNumber: string;
  customerNumber: string;
  medicalAidPositionNumber: string;
  ean: string;
  additionalDescription: string;
  manufacturer: string;
  size: string;
  status: number;
  productGroupId: string;
  therapyIds: number[] = [];
  billableTherapies: number[] = [];
  prescriptionType: number;
  packagingUnits: IQuantityPerUnit[] = [];
  pharmacyPrice: string;
  listPrice: string;
  fixedPrice: string;
  purchaseRating: number;
  requiresSerialnumber: boolean;
  requiresBatchnumber: boolean;
  requiresValidToDate: boolean;
  resmedTenant: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  backorderInformation?: string;
  smofkabiven: { startDate: Date; endDate: Date };

  get displayText(): string {
    return this.name;
  }

  public findPackagingUnit(packagingUnitId: string): IQuantityPerUnit {
    return (this.packagingUnits || []).find(packagingUnit => packagingUnit._id === packagingUnitId);
  }
}
